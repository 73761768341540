.tableCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px !important;  
  max-width: 215px;
}
.tableCellImg {
  max-width: unset;
}
