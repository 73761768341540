.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
	background-color: #141E30;
	background-image: radial-gradient(circle, #869ad3, #7289c1, #5e78ae, #4a689d, #35588b);
}

.authBlock {
	width: 680px;
	top: 0;
	position: sticky;
	margin-bottom: 25px !important;
}

.authContainer {
	margin: 25px;
}

.stack {
	margin: 25px 45px;
}

.title {
	text-transform: Uppercase;
	margin-bottom: .5em;
	font-family: 'Roboto', sans-serif;
	font-size: 4rem;
	color: #E4E5E6;
	position: relative;
	background-image: radial-gradient(circle, #cedff8, #bfd3f2, #b1c7ec, #a3bbe6, #95afe0);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 10s linear infinite;
}


.title:before,
.title:after {
	content: attr(data-text);
	position: absolute;
	top: 0;
	left: 0;
}

.title:before {
	z-index: -1;
	text-shadow: -0.001em -0.001em 1px rgba(255, 255, 255, .15);
}

.title:after {
	z-index: -2;
	text-shadow: 10px 5px 5px rgba(0, 0, 0, .1), 10px 10px 10px rgba(0, 0, 0, .2), 15px 15px 15px rgba(0, 0, 0, .1);
	mix-blend-mode: multiply;
}

.title2 {
	margin-top: -1.15em;
	font-family: 'Roboto', sans-serif;
	font-size: 2rem;
	color: #F7971E;
	letter-spacing: .1em;
	text-align: center;
	opacity: 0.8;
	text-shadow: 0px -1px 1px rgba(255, 255, 255, .2), 1px 1px 1px rgba(13, 0, 100, 0.5);
}

.subtitle {
	margin: 15px 0 0 7px !important;
	font-size: 1rem !important;
	color: #35588b !important;
}

@keyframes textclip {
	to {
		background-position: 600% center;
	}
}